.root {
  :global {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  }

  display: flex;
  justify-content: space-between;
  min-width: 100%;
  height: 100vh;
  font-size: 16px;
  background-image: linear-gradient(to right top, #2d3c43, #252b2e);
}

.main {
  margin: 0 auto;
  width: 35%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 3rem;

  &__header {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    justify-content: space-around;
    text-align: center;

    &__img {
      width: 180px;
      height: auto;
    }

    &__heading {
      color: #fff;
      font-size: 45px;
      font-weight: 300;
    }

    &__text {
      font-size: 20px;
      font-weight: 300;
      color: #607d8b;
      line-height: 1.5;

      &__link {
        color: #00abce;
        text-decoration: none;
      }
    }
  }
}

.logos {
  text-align: center;
  float: left;

  &__text {
    color: #50d2c2;
    font-size: 20px;
  }

  &__imgContainer {
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    color: #ffffff;

    &__img {
      width: 300px;
    }
  }
}

.ieImgContainer {
  display: flex;
  align-items: center;
  background-image: url(/images/ie-image.png);
  background-position: center;
  background-size: cover;
  width: 50%;
  height: 100%;
}
