@font-face {
unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
font-family: 'latoLatinExtFont';
src: url(/_next/static/media/6ebed106d6de17e2.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
font-family: 'latoLatinExtFont';
src: url(/_next/static/media/17108e38b290effa.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
font-family: 'latoLatinExtFont';
src: url(/_next/static/media/8697d1d596a20b96.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
font-family: 'latoLatinExtFont';
src: url(/_next/static/media/df76895ef4e4f2da.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}.__className_b5a20b {font-family: 'latoLatinExtFont'
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoLatinFont';
src: url(/_next/static/media/b7ffde2383bb16ba-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoLatinFont';
src: url(/_next/static/media/0ed72b868859dbf2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoLatinFont';
src: url(/_next/static/media/d5eb20bcdcf5616a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
font-family: 'latoLatinFont';
src: url(/_next/static/media/9c868634695237b9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: 'latoLatinFont Fallback';src: local("Arial");ascent-override: 100.88%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 97.84%
}.__className_8e4c10 {font-family: 'latoLatinFont', 'latoLatinFont Fallback'
}

.unsupported-browser_root__6DJyS{display:flex;justify-content:space-between;min-width:100%;height:100vh;font-size:16px;background-image:linear-gradient(to right top, #2d3c43, #252b2e)}.unsupported-browser_root__6DJyS *{margin:0;padding:0;box-sizing:border-box}.unsupported-browser_main__azhf4{margin:0 auto;width:35%;background-size:cover;background-position:center;display:flex;flex-direction:column;justify-content:space-evenly;padding:0 3rem}.unsupported-browser_main__header__UW5nO{display:flex;flex-direction:column;min-height:500px;justify-content:space-around;text-align:center}.unsupported-browser_main__header__img__Pd6g7{width:180px;height:auto}.unsupported-browser_main__header__heading__NIk7I{color:#fff;font-size:45px;font-weight:300}.unsupported-browser_main__header__text__Lhzu_{font-size:20px;font-weight:300;color:#607d8b;line-height:1.5}.unsupported-browser_main__header__text__link__IvXPy{color:#00abce;text-decoration:none}.unsupported-browser_logos__oDOYF{text-align:center;float:left}.unsupported-browser_logos__text__qkvcv{color:#50d2c2;font-size:20px}.unsupported-browser_logos__imgContainer__Vfd8t{margin:20px auto 0 auto;display:flex;justify-content:center;align-items:center;width:80%;color:#fff}.unsupported-browser_logos__imgContainer__img__uZuUN{width:300px}.unsupported-browser_ieImgContainer__KJVMD{display:flex;align-items:center;background-image:url(/images/ie-image.png);background-position:center;background-size:cover;width:50%;height:100%}
